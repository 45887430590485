<template>
  <div
    class="flex justify-middle w-full min-h-screen -mt-10 lg:mt-0 pb-40 lg:pb-0"
    v-if="!isAuthenticated"
  >
    <div class="flex flex-wrap w-10/12 lg:w-9/12 h-5/6 lg:h-4/5 m-auto">
      <div
        class="
          px-10
          lg:pl-20 lg:pr-16
          bg-white
          dark:bg-base5
          flex
          w-full
          lg:h-full
          m-auto
          lg:w-1/2
          rounded-t-3xl
          lg:rounded-none lg:rounded-l-3xl
          flex flex-col
          justify-between
          overflow-auto
        "
      >
        <div v-if="!signingIn" class="mt-12 lg:mt-16">
          <div class="text-grey1">
            <a href="https://meetric.app"
              ><img
                class="w-28 my-2"
                src="../assets/logos/meetric-logo-signin.webp"
            /></a>
            <p class="text-base3 text-4xl font-semibold">Welcome</p>
            <p class="leading-loose my-6 lg:mt-10">
              <template v-if="meetingSummary">
                Sign in to access
                <span class="font-bold">{{ meetingSummary }}</span> and join
                your colleagues in Meetric (it's free):
              </template>
              <template v-else>
                To get started, sign in to Meetric with your work email:
              </template>
            </p>
            <div class="mx-auto text-base">
              <signin-btn label="Google" @click="signIn" />
            </div>
            <div class="mt-5 mx-auto text-base">
              <signin-btn label="Microsoft" />
            </div>
            <hr class="my-6 lg:my-10 border-grey6 w-4/5" />
            <!--
          <div class="mx-auto p-4 text-base">
            <a rel="noopener" target="_blank" href="https://meetricapp.typeform.com/to/HnuiaOhn">
                
              <button class="m-signin" @click="$emit('signin', 'microsoft')">
              <button class="m-signin" >
                <span class="icon"></span>
                <span class="text">Sign in with Microsoft</span>
              </button>
            </a>
          </div>
          -->
            <p>
              <a-link
                segmentName="How do you protect my data?"
                href="https://meetric.notion.site/Data-protection-910e33d7e1c241cca9f78261dee86ab6"
                >How do you protect my data?</a-link
              >
            </p>
            <p class="mt-3">
              <a-link
                segmentName="Show me a dummy meeting in Meetric"
                href="https://my.meetric.app/meetings/5v5hgqp8u8h7i7nb8lf8f8djcf"
                >Show me a meeting in Meetric</a-link
              >
            </p>
            <p class="mt-3 mb-6">
              <a-link segmentName="What is Meetric?" href="https://meetric.app"
                >What is Meetric?</a-link
              >
            </p>
          </div>
        </div>
        <div v-if="!signingIn" class="flex text-sm text-grey2 mb-5">
          <p>
            By signing in I agree to Meetric's
            <a-link
              segmentName="terms of use"
              :href="baseURL + 'termsofservice.html'"
              >terms of use</a-link
            >
            and
            <a-link
              segmentName="privacy policy"
              :href="baseURL + 'privacypolicy.html'"
              >privacy policy</a-link
            >
          </p>
        </div>

        <div v-if="signingIn" class="m-auto text-grey2 py-16">
          <div>Opening your account...</div>
          <div class="mx-auto mt-6">
            <svg
              class="animate-spin mx-auto p-1 h-12 w-12 text-grey4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      </div>

      <!-- Demo video panel -->
      <!-- <div
        class="signin-panel flex pl-20 pt-16 lg:h-full m-auto w-full lg:w-1/2 rounded-b-3xl lg:rounded-none lg:rounded-r-3xl mb-8 lg:mb-0"
      >
        <div class="text-4xl font-semibold flex flex-col justify-between">
          <div class="mb-6">
            <p class="text-base1">Productive.</p>
            <p class="text-base2 text-opacity-50 -mt-2" style="color: #acc2d9">
              Meetings.
            </p>
          </div>
          <div class="overflow-hidden rounded-br-3xl">
            <video
              class="rounded-tl-2xl"
              style="max-width: 130%"
              playsinline=""
              muted=""
              loop=""
              autoplay=""
              poster="https://uploads-ssl.webflow.com/5fc569bc1ff0f05301fbe22c/5ff51fc6711fd8feef1eeb17_Screen%20Shot%202021-01-06%20at%201.24.12%20pm.png"
              src="https://lumbergh.s3-ap-southeast-2.amazonaws.com/www/meetric_demonstration_210113.mp4?raw=1"
            ></video>
          </div>
        </div>
      </div> -->
      <div
        class="
          signin-panel-bg
          flex
          px-10
          lg:px-20 lg:h-full
          m-auto
          w-full
          lg:w-1/2
          rounded-b-3xl
          lg:rounded-none lg:rounded-r-3xl
          mb-0
        "
      >
        <div class="text-basebackground flex flex-col my-auto font-medium">
          <div class="mx-auto mb-8 mt-10 lg:mt-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M11 9.275c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275zm13 0c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275z"
              />
            </svg>
          </div>
          <div class="text-center text-2xl mb-8">
            One of the best products I've used in a LONG time! Makes me feel
            like a meeting productivity superhero and helps me stay on top of my
            meetings, actions, and keeps everyone involved accountable.
          </div>
          <div class="text-center text-xl mb-10 lg:mb-0">
            Elizabeth van Rooyen<br />
            Co-Founder, Diligr
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SigninBtn from '@/components/UI/SigninBtn';
import ALink from '@/components/UI/ALink';
import { startUserSession, hasScopeNeeded } from '@/components/Utils';
// import EmojiSwitch from '@/components/EmojiSwitch';
import { trackPublicWeb } from './SignInWeb.vue';
export default {
  name: 'Home',
  components: {
    SigninBtn,
    ALink,
    // EmojiSwitch,
  },
  data() {
    return {
      signingIn: false,
      cameFromWeb: false,
      meetingSummary: null,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$gAuth.isAuthorized;
    },
    isExtension() {
      return !!this.$store?.getters['isExtension'];
    },
    baseURL() {
      return process.env.VUE_APP_PUBLIC_ENDPOINT;
    },
  },
  mounted() {
    // we can pass meeting name if we know
    if (this.$route.query.summary !== undefined) {
      this.meetingSummary = this.$route.query.summary;
    }

    if (this.$route.query.web !== undefined) {
      this.cameFromWeb = true;
    }

    if (this.isAuthenticated) {
      const scope =
        this.$gAuth?.GoogleAuth?.currentUser?.get().getGrantedScopes() ?? '';

      if (!hasScopeNeeded(scope)) {
        console.log('Not all permissions granted');
        this.$snack('Please grant permissions to your calendar');
        window.meetric.track('Permissions not granted');
        this.$router.replace({
          name: 'signout',
        });
      } else {
        this.$router.replace({
          name: 'home',
        });
      }
    } else {
      // not signed in - either before login or after login error
      this.startOneTapLogin();
      if (this.$route.hash) {
        // sign cancelled
        const result = this.$route.hash
          .substr(1)
          .split('&')
          .reduce(function (res, item) {
            let parts = item.split('=');
            res[parts[0]] = parts[1];
            return res;
          }, {});
        if (result.error) this.signInFailure(result.error);
      }
    }
  },
  beforeDestroy() {},
  methods: {
    signIn: async function () {
      this.$gAuth
        .signInSelectAccount()
        .then((user) => {
          return this.signInSuccess(user);
        })
        .catch((error) => this.signInFailure(error));
    },
    startOneTapLogin() {
      this.$gAuth
        .startOneTap()
        .then((user) => this.signInSuccess(user))
        .catch((error) => this.signInFailure(error));
    },
    signInSuccess: async function (user) {
      this.signingIn = true;
      if (!hasScopeNeeded(user.getAuthResponse().scope)) {
        // not all scopes granted
        console.log('Not all permissions granted');
        this.$snack('Please grant permissions to your calendar');
        window.meetric.track('Permissions not granted');
        this.signingIn = false;
        return;
      }
      this.$gAuth.closeOneTap();
      const basicProfile = user.getBasicProfile();

      if (this.cameFromWeb) trackPublicWeb();
      // let p = await getMeetricProfile(basicProfile.getEmail());

      await startUserSession(basicProfile, this.$route);
    },
    signInFailure(error) {
      console.error('Signin error', error);
      window.meetric.track('User declined sign-in', { error: error });
      this.$snack('Please grant permissions to your calendar');
    },
  },
};
</script>
<style>
.signin-panel {
  background-color: #e4efff;
}

.dark .signin-panel {
  background-color: rgb(48, 48, 48);
}

.bg-basebackground.signin {
  background-image: linear-gradient(to right, #1d263b, #324061);
}
</style>
<style scoped>
.signin-panel-bg {
  background-image: linear-gradient(to right, #8f2dee, #e1854b);
}

.right-panel-item {
  @apply inline-flex mb-8 items-baseline;
}
.right-panel-item span {
  @apply ml-2;
}
</style>
