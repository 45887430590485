<template>
  <div>
    <div
      v-if="label == 'Google'"
      class="g-signin"
      :class="customClass"
      @click.prevent.stop="onClick"
      @keypress.enter.prevent.stop="onClick"
    >
      <span class="icon"></span>
      <span class="text">Sign in with {{ label }}</span>
    </div>
    <div v-else class="m-signin border" @click="onClick" @keypress="onClick">
      <a href="https://meetricapp.typeform.com/to/HnuiaOhn" target="_blank">
        <span class="icon"></span>
        <span class="text">Sign in with {{ label }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignInBtn',
  props: {
    label: { type: String, default: 'Sign In with Google' },
    'custom-class': { type: String, default: '' },
  },
  methods: {
    onClick() {
      this.$emit('click');

      const props = {
        source: this.$parent?.$parent?.$vnode?.tag || '',
        type: 'Button click',
      };
      window.meetric.track('Sign in with ' + this.label, props);
    },
  },
};
</script>

<style scoped>
.g-signin,
.m-signin {
  display: inline-block;
  background: #fff;
  color: #555;
  border-radius: 8px;
  border: 1px solid rgb(243, 243, 243);
  width: 230px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.g-signin:hover,
.m-signin:hover {
  cursor: pointer;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  color: #444;
}

.g-signin span.icon,
.m-signin span.icon {
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 42px;
}

.g-signin span.icon {
  background-image: url('~@/assets/logos/g-logo.webp'),
    url('~@/assets/logos/g-logo.png');
}
.m-signin span.icon {
  background-image: url('~@/assets/logos/m-logo.webp'),
    url('~@/assets/logos/m-logo.png');
}
.g-signin span.text,
.m-signin span.text {
  @apply font-semibold;
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 15px;
  padding-top: 1px;
  font-size: 14px;
}
</style>
