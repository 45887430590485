<template>
  <div></div>
</template>

<script>
import { hasScopeNeeded } from '@/components/Utils';

(function (i, s, o, g, r, a, m) {
  i['GoogleAnalyticsObject'] = r;
  (i[r] =
    i[r] ||
    function () {
      (i[r].q = i[r].q || []).push(arguments);
    }),
    (i[r].l = 1 * new Date());
  (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
  a.async = 1;
  a.src = g;
  m.parentNode.insertBefore(a, m);
})(
  window,
  document,
  'script',
  'https://www.google-analytics.com/analytics.js',
  'ga'
);

export default {
  name: 'SignInWeb',
  mounted() {
    //console.log('web signin');
    const scope =
      this.$gAuth?.GoogleAuth?.currentUser?.get().getGrantedScopes() ?? '';

    if (!hasScopeNeeded(scope)) {
      console.log('Not all permissions granted');
      this.$snack('Please grant permissions to your calendar');
      window.meetric.track('Permissions not granted');
      this.$router.replace({
        name: 'signout',
        query: { web: null },
      });
    } else {
      // public web GA
      trackPublicWeb();
    }
  },
};

export function trackPublicWeb() {
  window.ga('create', 'UA-162785732-1', 'auto');
  window.ga('send', 'event', 'All', 'Homepage Google');
}
</script>
